import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Routes, Route, Navigate } from 'react-router-dom'

import EmailVerificationError from '@/components/EmailVerificationForm/EmailVerificationError'
import EmailVerificationForm from '@/components/EmailVerificationForm/EmailVerificationForm'
import CreateNewPasswordForm from '@/components/Password/CreateNewPasswordForm/CreateNewPasswordForm'
import PasswordResetForm from '@/components/Password/PasswordResetForm/PasswordResetForm'
import PasswordSuccess from '@/components/Password/PasswordSuccess/PasswordSuccess'
import { Box } from '@mui/material'
import MidmarkV3Layout from '@/layouts/MidmarkV3Layout'
import { MidmarkV4Layout } from '@/layouts/MidmarkV4Layout'
import { UserVerificationService } from './services/UserVerificationService/UserVerificationService'
import { SimplePager } from './components/Shared/SimplePager'
import UserSignupWizard from './components/UserSignUpStepper/user-signup-modal.component'

export interface AppUser {
    identificationGuid?: string
    firstName: string
    lastName: string
    emailAddress: string
}

export interface AppEmailVerification {
    id: string | null
    emailAddress: string | null
    code: string | null
}

const getQuery = () => new URLSearchParams(window.location.search)

export default function App() {
    const navigate = useNavigate()

    const [emailVerification, setEmailVerification] =
        useState<AppEmailVerification>({
            id: import.meta.env.VITE_EMAIL_VERIFICATION_ID || null,
            emailAddress: import.meta.env.VITE_USER_EMAIL || '',
            code: import.meta.env.VITE_EMAIL_VERIFICATION_CODE || null,
        })

    const [redirectUri, setRedirectUri] = useState<string>(
        import.meta.env.VITE_MIDMARK_PORTAL_URL,
    )
    const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false)

    const updateEmailVerification = useCallback(
        (
            id: string | null,
            emailAddress: string,
            code?: string | null,
            callback?: () => void,
        ) => {
            setEmailVerification({
                id: id ?? null,
                emailAddress: emailAddress ?? '',
                code: code ?? null,
            })

            if (callback) {
                callback()
            }
        },
        [],
    )

    document.title = 'Midmark'

    const query = getQuery()

    const verificationRequestId = query.get('verificationRequestId')
    const email = query.get('email')
    const code = query.get('code')

    useEffect(() => {
        if (email) {
            updateEmailVerification(null, email)
        }
    }, [email, updateEmailVerification])

    const verifyEmailMutation =
        UserVerificationService.useVerifyEmailVerificationCodeMutation()

    useEffect(() => {
        if (verifyEmailMutation.isLoading) {
            return
        }

        if (
            emailVerification.id === verificationRequestId &&
            verificationRequestId != null
        ) {
            return
        }

        if (verificationRequestId && email && code) {
            verifyEmailMutation
                .mutateAsync({
                    id: verificationRequestId,
                    code: code,
                    email: email,
                })
                .then((response) => {
                    if (response.isValid) {
                        updateEmailVerification(
                            verificationRequestId,
                            email,
                            code,
                        )
                    } else {
                        updateEmailVerification(null, email)
                        console.log(response)
                        navigate('/email-verification-error')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    navigate('/email-verification-error')
                })
                .finally(() => {
                    setIsAppLoaded(true)
                })
        } else {
            setIsAppLoaded(true)
        }
    }, [
        code,
        email,
        emailVerification.id,
        navigate,
        updateEmailVerification,
        verificationRequestId,
        verifyEmailMutation,
    ])

    if (
        query.has('redirect_uri') &&
        redirectUri != (query.get('redirect_uri') as string)
    ) {
        setRedirectUri(query.get('redirect_uri') as string)
    }

    return !isAppLoaded ? (
        <div></div>
    ) : (
        <Box
            sx={{
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
            }}
        >
            <Routes>
                <Route element={<MidmarkV4Layout />}>
                    <Route
                        path="/password/reset"
                        element={
                            <SimplePager>
                                <PasswordResetForm
                                    email={emailVerification.emailAddress || ''}
                                    updateEmailVerification={
                                        updateEmailVerification
                                    }
                                    redirectUri={redirectUri || ''}
                                />
                                <EmailVerificationForm
                                    isCompact={true}
                                    emailVerification={emailVerification}
                                    updateEmailVerification={
                                        updateEmailVerification
                                    }
                                />
                                <CreateNewPasswordForm
                                    emailVerification={emailVerification}
                                />
                                <PasswordSuccess
                                    emailVerification={emailVerification}
                                    updateEmailVerification={
                                        updateEmailVerification
                                    }
                                />
                            </SimplePager>
                        }
                    />

                    <Route
                        path="/password/create"
                        element={
                            <SimplePager>
                                <CreateNewPasswordForm
                                    emailVerification={emailVerification}
                                />
                                <PasswordSuccess
                                    emailVerification={emailVerification}
                                    updateEmailVerification={
                                        updateEmailVerification
                                    }
                                />
                            </SimplePager>
                        }
                    />

                    <Route
                        path="/new-user/sign-up"
                        element={<UserSignupWizard />}
                    />
                </Route>
                <Route element={<MidmarkV3Layout />}>
                    <Route
                        path="/"
                        element={<Navigate to="/password/reset" />}
                    />

                    <Route
                        path="/email-verification-error"
                        element={
                            <EmailVerificationError
                                updateEmailVerification={
                                    updateEmailVerification
                                }
                                emailVerification={emailVerification}
                            />
                        }
                    />
                    <Route path="/*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </Box>
    )
}
