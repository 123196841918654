import { AppEmailVerification } from '@/App'
import {
    BrowserWindowIcon,
    LockIcon,
    MidmarkLink,
    MidmarkTypography,
} from '@midmark-enterprise/shared-components'
import { Grid, Box } from '@mui/material'
import { useEffect } from 'react'

const InfoWithIcon = ({
    icon,
    message,
}: {
    icon: React.ReactNode
    message: string
}) => (
    <Grid container alignItems="center" wrap="nowrap" mb={2}>
        <Box
            sx={(theme) => ({
                '& svg': { fontSize: 36 },
                display: 'flex',
                alignItems: 'center',
                mr: 1.5,
                color: theme.palette.text.hint,
            })}
        >
            {icon}
        </Box>
        <MidmarkTypography sx={{ flexShrink: 1 }} variant="body2">
            {message}
        </MidmarkTypography>
    </Grid>
)

export default function PasswordSuccess({
    emailVerification,
    updateEmailVerification,
}: {
    emailVerification: AppEmailVerification
    updateEmailVerification: (
        id: string | null,
        emailAddress: string,
        code?: string,
        callback?: () => void,
    ) => void
}) {
    useEffect(() => {
        if (emailVerification.id && emailVerification.emailAddress) {
            updateEmailVerification(null, emailVerification.emailAddress)
        }
    }, [
        emailVerification.emailAddress,
        emailVerification.id,
        updateEmailVerification,
    ])

    return (
        <>
            <MidmarkTypography
                paragraph={true}
                variant="h3"
                data-testid={DATA_TESTIDS.successTextField}
            >
                Password Set Successfully!
            </MidmarkTypography>

            <MidmarkTypography paragraph={true} variant="body1">
                Things you should know
            </MidmarkTypography>

            <InfoWithIcon
                icon={<LockIcon />}
                message="Your new password applies anywhere you use a Midmark Login"
            />
            <InfoWithIcon
                icon={<BrowserWindowIcon />}
                message="You can close this window at any time and return to your Midmark product"
            />

            <MidmarkTypography sx={{ mt: 2 }} paragraph={true} variant="body2">
                Quick links
            </MidmarkTypography>

            <MidmarkLink
                component="a"
                href="https://www.midmark.com"
                paragraph={true}
            >
                midmark.com
            </MidmarkLink>

            <MidmarkLink
                component="a"
                href={import.meta.env.VITE_MIDMARK_PORTAL_URL}
                paragraph={true}
            >
                mymidmark.com
            </MidmarkLink>
        </>
    )
}

const DATA_TESTIDS = {
    successTextField: 'success-text-field',
}
