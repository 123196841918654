import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import {
    ErrorIcon,
    MidmarkInlineNotification,
    MidmarkLink,
} from '@midmark-enterprise/shared-components'
import { FormikProps } from 'formik'
import { useCallback, useState } from 'react'

export type ResendVerificationCodeLinkProps = {
    formikProps: FormikProps<any>
    onNewIdentificationGuid: (identificationGuid: string) => void
    emailAddress: string
}

export default function ResendVerificationCodeLink({
    formikProps,
    onNewIdentificationGuid,
    emailAddress,
}: ResendVerificationCodeLinkProps) {
    const [isSendingNewCode, setIsSendingNewCode] = useState(false)
    const [codeResent, setIsCodeResent] = useState(false)
    const sendVerificationEmailMutation =
        UserVerificationService.useSendVerificationEmailMutation()

    const sendNewCode = useCallback(
        (props: FormikProps<any>) => {
            if (isSendingNewCode) {
                return
            }
            setIsSendingNewCode(true)

            if (!emailAddress) {
                throw new Error('Missing email address')
            }

            sendVerificationEmailMutation
                .mutateAsync({
                    emailAddress: emailAddress,
                    isNewUserRegistration: false,
                })
                .then((data) => {
                    onNewIdentificationGuid(data.identificationGuid)
                    setIsSendingNewCode(false)

                    props.resetForm()
                    setIsCodeResent(true)
                })
                .catch((error) => console.log(error))
        },
        [
            emailAddress,
            isSendingNewCode,
            onNewIdentificationGuid,
            sendVerificationEmailMutation,
        ],
    )

    return (
        <>
            <MidmarkLink
                component={'button'}
                type="button"
                onClick={() => sendNewCode(formikProps)}
                data-testid={DATA_TESTIDS.resendCodeButton}
            >
                Resend code
            </MidmarkLink>

            {codeResent &&
                !sendVerificationEmailMutation.isLoading &&
                sendVerificationEmailMutation.isSuccess && (
                    <MidmarkInlineNotification
                        sx={{ mt: 1 }}
                        severity="success"
                        forceOpen={true}
                        data-testid={DATA_TESTIDS.successNotification}
                    >
                        Code sent successfully
                    </MidmarkInlineNotification>
                )}

            {sendVerificationEmailMutation.isError && (
                <MidmarkInlineNotification
                    sx={{ mt: 1 }}
                    icon={<ErrorIcon />}
                    severity="error"
                    forceOpen={true}
                    data-testid={DATA_TESTIDS.errorNotification}
                >
                    We were unable to resend your code. Please try again.
                </MidmarkInlineNotification>
            )}
        </>
    )
}

export const DATA_TESTIDS = {
    resendCodeButton: 'resend-code-button',
    successNotification: 'success-notification',
    errorNotification: 'error-notification',
}
