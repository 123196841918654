import { Formik, FormikProps } from 'formik'
import { AppEmailVerification } from '@/App'
import { useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { FormLayout } from '@/components/Shared/FormLayout'
import {
    MidmarkButton,
    MidmarkFormikTextField,
} from '@midmark-enterprise/shared-components'
import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import { useSimplePager } from '../Shared/SimplePager'
import ResendVerificationCodeLink from './ResendVerificationCodeLink'

type LocationState = {
    backUrl: string
    forwardUrl: string
}

type EmailVerificationFormProps = {
    emailVerification: Partial<AppEmailVerification>
    updateEmailVerification: (
        id: string | null,
        emailAddress: string,
        code?: string,
        callback?: () => void,
    ) => void
    isCompact: boolean
}

export default function EmailVerificationForm({
    emailVerification,
    updateEmailVerification,
}: EmailVerificationFormProps) {
    const simplePager = useSimplePager()
    const verifyEmailVerificationCodeMutation =
        UserVerificationService.useVerifyEmailVerificationCodeMutation()

    const [formEmailVerification, setFormEmailVerification] =
        useState(emailVerification)
    const [formFilled, setFormFilled] = useState(false)

    const moveForward = useCallback(() => {
        simplePager.nextPage()
    }, [simplePager])

    const moveBackward = useCallback(() => {
        simplePager.previousPage()
    }, [simplePager])

    useEffect(() => {
        if (!formEmailVerification.id) {
            moveBackward()
        }
    }, [formEmailVerification.id, moveBackward])

    const submitForm = useCallback((props: FormikProps<any>) => {
        if (!props.isSubmitting) {
            props.submitForm()
        }
    }, [])

    const handleSubmit = useCallback(
        (
            values: { verifyEmail: string },
            action: {
                setFieldError: (fieldName: string, errorMessage: string) => void
                setSubmitting: (isSubmitting: boolean) => void
            },
        ) => {
            const code = values.verifyEmail.toUpperCase()

            if (!formEmailVerification.id) {
                throw new Error('Missing formEmailVerification.id')
            }

            if (!formEmailVerification.emailAddress) {
                throw new Error('Missing formEmailVerification.emailAddress')
            }

            verifyEmailVerificationCodeMutation
                .mutateAsync({
                    id: formEmailVerification.id,
                    code: code,
                    email: formEmailVerification.emailAddress,
                })
                .then((response) => {
                    setFormFilled(false)

                    action.setSubmitting(false)
                    if (response.isValid) {
                        if (!formEmailVerification.id) {
                            throw new Error('Missing formEmailVerification.id')
                        }

                        if (!formEmailVerification.emailAddress) {
                            throw new Error(
                                'Missing formEmailVerification.emailAddress',
                            )
                        }

                        updateEmailVerification(
                            formEmailVerification.id,
                            formEmailVerification.emailAddress,
                            code,
                        )

                        moveForward()
                    } else {
                        action.setFieldError(
                            'verifyEmail',
                            'Code not correct. Try again.',
                        )
                    }
                })
                .catch((error) => console.log(error))
        },
        [
            formEmailVerification.emailAddress,
            formEmailVerification.id,
            moveForward,
            updateEmailVerification,
            verifyEmailVerificationCodeMutation,
        ],
    )

    const validate = useCallback((values: { verifyEmail: string }) => {
        if (values.verifyEmail.length === 6) {
            setFormFilled(true)
        }
    }, [])

    const { emailAddress } = formEmailVerification
    const obfuscatedEmailAddress = useMemo(
        () => emailAddress?.replace(/^(.{2})[^@]+/, '$1***'),
        [emailAddress],
    )
    return (
        <Formik
            initialValues={{
                verifyEmail: '',
            }}
            validateOnChange={false}
            validateOnBlur={true}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <>
                    <FormLayout
                        title="Verify Email"
                        description={`We just sent an email to the address you provided, ${obfuscatedEmailAddress}. Check your inbox for a verification code and enter it here`}
                        buttons={
                            <>
                                <MidmarkButton
                                    onClick={moveBackward}
                                    data-testid={DATA_TESTIDS.backButton}
                                    color="secondary"
                                >
                                    Back
                                </MidmarkButton>
                                <MidmarkButton
                                    isSubmitting={props.isSubmitting}
                                    data-testid={DATA_TESTIDS.verifyButton}
                                    type="submit"
                                >
                                    Verify
                                </MidmarkButton>
                            </>
                        }
                    >
                        <MidmarkFormikTextField
                            label="Verification Code"
                            placeholder="Enter verification code"
                            formikProps={props}
                            autoComplete="off"
                            name="verifyEmail"
                            data-testid={DATA_TESTIDS.verificationCodeTextField}
                            sx={{
                                '& input': {
                                    textTransform: 'uppercase',
                                    '&::placeholder': {
                                        textTransform: 'none',
                                    },
                                },
                            }}
                        />

                        <ResendVerificationCodeLink
                            emailAddress={
                                formEmailVerification.emailAddress as string
                            }
                            formikProps={props}
                            onNewIdentificationGuid={(guid) => {
                                if (!formEmailVerification.emailAddress) {
                                    throw new Error('Missing email address')
                                }
                                updateEmailVerification(
                                    guid,
                                    formEmailVerification.emailAddress,
                                )
                                setFormEmailVerification({
                                    ...formEmailVerification,
                                    id: guid,
                                })
                            }}
                        />
                    </FormLayout>
                    {formFilled && submitForm(props)}
                </>
            )}
        </Formik>
    )
}

export const DATA_TESTIDS = {
    verifyButton: 'verify-button',
    backButton: 'back-button',
    verificationCodeTextField: 'verification-code-text-field',
    formikForm: 'formik-form',
}
